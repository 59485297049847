import * as React from 'react'
import tw, { styled } from 'twin.macro'
import { motion, MotionValue } from 'framer-motion'
import NavLogo from './NavLogo'
import { Container } from './Elements'
import NavMenu from './NavMenu'

const NavBlock = styled(motion.div)(() => [
  tw`flex flex-row justify-between items-center h-24 xxs:px-2 md:px-0`,
])

const Nav = ({
  backgroundColor,
  boxShadow,
}: {
  backgroundColor: MotionValue<string>
  boxShadow: MotionValue<string>
}) => {
  return (
    <NavBlock tw="sticky top-0 z-50" style={{ backgroundColor, boxShadow }}>
      <Container tw="flex-row items-center justify-between">
        <NavLogo />
        <NavMenu />
      </Container>
    </NavBlock>
  )
}

export default Nav
