import * as React from 'react'
import tw, { styled } from 'twin.macro'
import { motion } from 'framer-motion'
import { StaticImage } from 'gatsby-plugin-image'

const NavLogoContainer = styled(motion.div)(() => [
  tw`flex flex-row justify-start h-16 w-40`,
])

const NavLogo: React.FC<{}> = () => {
  return (
    <NavLogoContainer>
      <StaticImage
        src="../../assets/images/branding/HazelSoftware_Logo_V1_Minimal_FullColor.svg"
        alt="hazel software logo"
        loading="eager"
        objectFit="contain"
        placeholder="tracedSVG"
        layout="constrained"
      />
    </NavLogoContainer>
  )
}

export default NavLogo
