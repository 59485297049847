import * as React from 'react'
import tw, { css, styled, theme } from 'twin.macro'
import { motion, AnimatePresence } from 'framer-motion'
import { nanoid } from 'nanoid'
import { mixColor, mix } from '@popmotion/popcorn'
import { StyledComponent } from 'styled-components'

export const HeadingTexts = css(() => [tw`font-sans`])

export const PrimaryTexts = css(() => [tw`text-gunmetal`])

export const BodyTexts = css(() => [tw`font-secondary font-normal`])

export const GradientTexts = css(
  ({ $gradientText }: { $gradientText?: string }) => [
    $gradientText
      ? css`
          background-image: linear-gradient(
            to bottom right,
            ${theme`colors.gold`} 0%,
            ${theme`colors.hazel`} 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          -moz-background-clip: text;
          -moz-text-fill-color: transparent;
          background-color: ${theme`colors.hazel`};
        `
      : undefined,
  ]
)

export const H1 = styled(motion.h1)(() => [
  tw`text-3xl font-bold`,
  HeadingTexts,
  PrimaryTexts,
  GradientTexts,
])

export const H2 = styled(motion.h2)(() => [
  tw`text-xl`,
  tw`font-bold`,
  HeadingTexts,
  PrimaryTexts,
  GradientTexts,
])

export const BigH2 = styled(H2)(() => [tw`xxs:text-4xl sm:text-6xl`])

export const H3 = styled(motion.h3)(() => [
  tw`text-base`,
  tw`font-bold`,
  HeadingTexts,
  PrimaryTexts,
  GradientTexts,
])

export const BigH3 = styled(H3)(() => [tw`xxs:text-xl md:text-2xl`])

export const P = styled(motion.p)(() => [
  tw`text-base`,
  tw`font-medium`,
  BodyTexts,
  PrimaryTexts,
])

export const BigP = styled(P)(() => [tw`text-lg`])

export const HeroH1 = styled(H1)(() => [
  tw`md:text-8xl sm:text-6xl xs:text-4xl`,
])

export const InnerText = styled(motion.span)(() => [GradientTexts])

interface ITextFragement {
  text: string
  useGradient: boolean
}

export const GradientTextRenderer: React.FC<{
  textConfig: ITextFragement[]
  TextComponent?: React.ReactNode
  GradientComponent?: React.ReactNode
}> = ({
  textConfig,
  TextComponent = HeroH1,
  GradientComponent = InnerText,
}) => {
  return (
    <InnerText>
      <TextComponent>
        {textConfig.map((t, i) => {
          if (t.useGradient)
            return (
              <GradientComponent
                key={i}
                $gradientText
                dangerouslySetInnerHTML={{ __html: t.text }}
              />
            )
          return <span key={i} dangerouslySetInnerHTML={{ __html: t.text }} />
        })}
      </TextComponent>
    </InnerText>
  )
}

/**
 * Rotating / sliding text
 * supporting components and styles
 */

export const TextRotator = ({
  message = 'See me go',
  gradientColorStart = 'rgba(0, 0, 0, 1)',
  gradientColorStop = 'rgba(0, 0, 0, 1)',
}) => {
  const [characters, setCharacters] = React.useState<
    { char: string; id: string }[]
  >(message.split('').map((char) => ({ char, id: nanoid() })))

  const layoutRefs = React.useRef([])

  React.useEffect(() => {
    const newChars = message.split('').map((char) => ({ char, id: nanoid() }))
    setCharacters(newChars)
  }, [message])

  React.useEffect(() => {
    layoutRefs.current = []
  }, [message])

  React.useLayoutEffect(() => {
    let where = 0
    layoutRefs.current.forEach((val, i) => {
      val.el.style.left = where + 'px'
      where += val.rect.width
    })
  }, [layoutRefs.current])

  const mixer = mixColor(gradientColorStart, gradientColorStop)

  return (
    <InnerText
      tw="flex flex-row items-center relative"
      variants={SlidingTextVariants}
      initial="beforeEnter"
      animate="display"
    >
      <AnimatePresence>
        {characters.map((char, i) => {
          return (
            <SlidingText
              key={char.id}
              tw="absolute flex items-center justify-center"
              variants={{
                beforeEnter: { opacity: 0, rotateX: -90 },
                display: () => {
                  const colorArray = Array(characters.length * 2).fill(null)
                  colorArray[0] = mixer(0)
                  colorArray[colorArray.length - 1] = mixer(0)
                  if (colorArray.length > 2) {
                    colorArray[colorArray.length / 2] = mixer(1)
                    colorArray[colorArray.length / 2 - 1] = mixer(1)
                  }
                  colorArray.forEach((d, k) => {
                    if (!d) {
                      if (k < colorArray.length / 2 - 1) {
                        colorArray[k] = mixer(k / (colorArray.length / 2 - 1))
                      }
                      if (k > colorArray.length / 2) {
                        colorArray[k] = mixer(
                          (colorArray.length - k) / (colorArray.length / 2)
                        )
                      }
                    }
                  })

                  // now shift it around based on length of word
                  const cACopy = colorArray.slice(colorArray.length - 1 - i)

                  let removal = i
                  while (removal > 0) {
                    colorArray.pop()
                    removal--
                  }
                  const lastArray = cACopy.concat(colorArray)

                  return {
                    opacity: 1,
                    rotateX: 0,
                    color: lastArray,
                    transition: {
                      opacity: {
                        duration: 0.25,
                        ease: 'backInOut',
                        delay: i * 0.1,
                      },
                      rotateX: {
                        duration: 0.25,
                        ease: 'backInOut',
                        delay: i * 0.1,
                      },
                      color: {
                        duration: 5,
                        ease: 'linear',
                        repeat: Infinity,
                      },
                    },
                  }
                },
              }}
              exit={{
                opacity: 0,
                rotateX: 90,
                transition: { delay: i * 0.1, ease: 'backInOut' },
              }}
              dangerouslySetInnerHTML={{ __html: char.char }}
              ref={(ref) => {
                if (!ref) return

                layoutRefs.current.push({
                  rect: ref.getBoundingClientRect(),
                  char: ref.textContent,
                  el: ref,
                  index: i,
                })
              }}
            />
          )
        })}
      </AnimatePresence>
    </InnerText>
  )
}

const SlidingText = styled(motion.span)(() => [
  'transform-origin: 50% 50% 25px;',
  GradientTexts,
])

const SlidingTextVariants: Record<string, unknown> = {
  beforeEnter: { opacity: 0 },
  display: { opacity: 1 },
}
