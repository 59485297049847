import React from 'react'

const useTimeout = (timeout = 0, fn = (...args: unknown[]) => {}) => {
  const fnRef = React.useRef(fn)

  const boundFn = React.useCallback(
    (...args: unknown[]) => {
      if (!fnRef.current) return
      const Fn = fnRef.current.bind(null, ...args)
      setTimeout(Fn, timeout)
    },
    [fnRef.current]
  )

  return boundFn
}

export default useTimeout
