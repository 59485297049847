import { motion } from 'framer-motion'
import * as React from 'react'
import tw, { css, styled, theme } from 'twin.macro'
import { Box } from './Elements'
import { BigH3, H3, InnerText } from './Typography'

const RadialBackground = styled(motion.div)(() => [])

export const Button = styled(motion.button)(() => [])

export const CTAButton = ({ buttonText = 'A CTA Button' }) => {
  return (
    <div tw="relative cursor-pointer p-1">
      <RadialBackground
        tw="z-10 absolute inset-0 rounded-3xl bg-center"
        animate={{
          background: [
            `linear-gradient(0deg, ${theme`colors.gold`} 0%, ${theme`colors.hazel`} 100%)`,
            `linear-gradient(360deg, ${theme`colors.gold`} 0%, ${theme`colors.hazel`} 100%)`,
            `linear-gradient(720deg, ${theme`colors.gold`} 0%, ${theme`colors.hazel`} 100%)`,
          ],
          transition: {
            duration: 4,
            ease: 'linear',
            repeat: Infinity,
          },
        }}
      />
      <Button tw="relative z-20 bg-clip-padding rounded-3xl shadow-lg bg-snow text-gunmetal xxs:px-4 sm:px-8 xxs:py-1 sm:py-2">
        <InnerText tw="whitespace-nowrap font-bold md:text-lg xxs:text-xs">
          {buttonText}
        </InnerText>
      </Button>
    </div>
  )
}

export const ActionButton = ({ buttonText = 'A CTA Button', emoji = '👋' }) => {
  return (
    <Box tw="relative cursor-pointer px-8 py-4 flex-row rounded-full bg-white shadow items-center justify-center xxs:w-full md:w-auto">
      <InnerText tw="whitespace-nowrap font-bold md:text-lg xxs:text-xs">
        {buttonText}
      </InnerText>
      <Box tw="flex-row justify-center absolute left-0 right-0 -top-4">
        <BigH3 style={{ textShadow: '0 1px 4px rgba(0, 0, 0, .08' }}>
          {emoji}
        </BigH3>
      </Box>
    </Box>
  )
}
