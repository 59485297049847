import { graphql, Link, PageProps, useStaticQuery } from 'gatsby'
import * as React from 'react'
import { Container, Main } from '../components/common/Elements'
import Default from '../components/layouts/default'
import {
  ContactUs,
  CTABar,
  Hero,
  Service,
} from '../components/pages/home/Elements'
import { IAppMessages } from '../types/messages'
// markup
const IndexPage = (_: PageProps) => {
  const {
    allMessagesJson: { nodes },
  } = useStaticQuery<{
    allMessagesJson: { nodes: IAppMessages[] }
  }>(graphql`
    query ServicesData {
      allMessagesJson {
        nodes {
          home {
            en {
              services {
                PRODUCT {
                  category
                  title
                  description
                  main_image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                ENGINEERING {
                  category
                  title
                  description
                  main_image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                ACADEMY {
                  category
                  title
                  description
                  main_image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Default>
      <Hero />
      <Container tw="relative xxs:-top-8 sm:-top-12 xxs:px-4 sm:px-0">
        <CTABar />
      </Container>
      <Main>
        <Service
          anchor="design"
          category={nodes[1].home.en.services['PRODUCT'].category}
          title={nodes[1].home.en.services['PRODUCT'].title}
          description={nodes[1].home.en.services['PRODUCT'].description}
          image={
            nodes[1].home.en.services['PRODUCT'].main_image.childImageSharp
              .gatsbyImageData
          }
          contained
          reverse
        />

        <Service
          anchor="engineering"
          category={nodes[1].home.en.services['ENGINEERING'].category}
          title={nodes[1].home.en.services['ENGINEERING'].title}
          description={nodes[1].home.en.services['ENGINEERING'].description}
          image={
            nodes[1].home.en.services['ENGINEERING'].main_image.childImageSharp
              .gatsbyImageData
          }
          contained
        />
        <Service
          anchor="academy"
          category={nodes[1].home.en.services['ACADEMY'].category}
          title={nodes[1].home.en.services['ACADEMY'].title}
          description={nodes[1].home.en.services['ACADEMY'].description}
          image={
            nodes[1].home.en.services['ACADEMY'].main_image.childImageSharp
              .gatsbyImageData
          }
          contained
          reverse
        />
        <ContactUs />
      </Main>
    </Default>
  )
}

export default IndexPage
