import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { styled, css, theme } from 'twin.macro'
import { AnimatePresence, motion } from 'framer-motion'
import { wrap } from '@popmotion/popcorn'
import { Link } from 'gatsby'
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image'
import {
  Aside,
  Box,
  Card,
  Container,
  Figure,
  GradientBackgroundBox,
  Header,
  Section,
} from '../../common/Elements'
import {
  BigH2,
  BigH3,
  BigP,
  H2,
  H3,
  HeroH1,
  InnerText,
  P,
  TextRotator,
} from '../../common/Typography'
import useTimeout from '../../../lib/hooks/useTimeout'

import HomeMessages from '../../../../data/messages/home.json'
import HSIcon from '../../../assets/images/branding/HazelSoftware_Logo_V1_Icon_FullColor.svg'
import { ActionButton } from '../../common/Buttons'

// i18n
const SELECTED_LANGUAGE = 'en'
const wrapMessage = wrap(
  0,
  HomeMessages.home[SELECTED_LANGUAGE].heroTexts.length
)

/**
 * HERO Elements
 */
const HeroImageContainer = styled(motion.div)(() => [
  css`
    & .hs-image-inner-container {
      width: 185%;
      height: 185%;
      & svg {
        width: 100%;
        height: 100%;
      }
    }
  `,
])

const HeroImage = () => {
  return (
    <HeroImageContainer tw="relative flex-shrink-0 flex absolute z-10 overflow-y-visible w-2/4 right-24">
      <Section tw="relative h-0 w-full pb-100P flex items-center justify-center xxs:-right-8">
        <Figure
          className="hs-image-inner-container"
          tw="absolute inset-0 xxs:-top-36 sm:-top-50P"
        >
          <HSIcon />
        </Figure>
      </Section>
    </HeroImageContainer>
  )
}

const HeroText = () => {
  const [currentMessage, setMessage] = React.useState(0)
  const message = React.useMemo(() => {
    return HomeMessages.home[SELECTED_LANGUAGE].heroTexts[currentMessage]
  }, [currentMessage])

  const changeMessage = React.useCallback((currentIndex) => {
    setMessage(wrapMessage(currentIndex + 1))
  }, [])

  const timedFn = useTimeout(5000, changeMessage)

  React.useEffect(() => {
    timedFn(currentMessage)
  }, [currentMessage])

  return (
    <HeroH1 tw="relative z-20 flex-1 flex flex-col flex-wrap justify-start">
      <InnerText tw="flex items-center">
        <InnerText
          tw="flex"
          dangerouslySetInnerHTML={{ __html: message.prefix }}
          css={[
            css`
              text-shadow: 0 1px 1px ${theme`colors.graphite`};
            `,
          ]}
        />
        <TextRotator
          message={message.highlight}
          gradientColorStart={theme`colors.gold`}
          gradientColorStop={theme`colors.hazel`}
        />
      </InnerText>

      <InnerText tw="flex relative">
        <InnerText
          style={{ color: 'transparent' }}
          dangerouslySetInnerHTML={{ __html: message.postfix }}
        />
        <AnimatePresence>
          {HomeMessages.home[SELECTED_LANGUAGE].heroTexts.map((t, k) => {
            return (
              currentMessage === k && (
                <InnerText
                  css={[
                    css`
                      text-shadow: 0 1px 1px ${theme`colors.graphite`};
                    `,
                  ]}
                  tw="absolute"
                  key={k}
                  initial="beforeEnter"
                  animate="display"
                  variants={{
                    display: {
                      opacity: 1,
                      x: 0,
                      transition: { duration: 0.8 },
                    },
                    beforeEnter: { opacity: 0, x: -100 },
                  }}
                  exit={{
                    opacity: 0,
                    x: 100,
                    transition: { duration: 0.8 },
                  }}
                  dangerouslySetInnerHTML={{ __html: t.postfix }}
                />
              )
            )
          })}
        </AnimatePresence>
      </InnerText>
    </HeroH1>
  )
}

export const Hero: React.FC = () => {
  return (
    <Header tw="bg-gradient-to-b from-snow to-golden-100 z-0 relative ">
      <Container tw="flex justify-between xxs:pt-14 md:pt-12 xxs:pb-20 md:pb-36 px-6 relative">
        <HeroText />
        <HeroImage />
      </Container>
    </Header>
  )
}

/**
 * CTA Bar
 */

export const CTABar = () => {
  const data = useStaticQuery(graphql`
    query CTAData {
      allMessagesJson {
        nodes {
          home {
            en {
              ctaLabel
              ctaItems {
                description
                label
                uri
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const ctaData = data.allMessagesJson.nodes.find((n) => n.home !== null)
  const {
    home: {
      en: { ctaItems, ctaLabel },
    },
  } = ctaData

  return (
    <Section tw="md:flex-row sm:flex-col justify-between">
      {ctaItems.map((cta, j) => {
        const ctaImage = cta.image
          ? getImage(cta.image.childImageSharp.gatsbyImageData)
          : null
        return (
          <Card
            key={j}
            tw="relative flex-1 w-full xxs:my-1 sm:my-0 xxs:mx-0 sm:mx-2 px-5 py-6 bg-neutral border-transparent border-2 cursor-pointer hover:-translate-y-2 transition-all"
            css={`
              &:hover {
                border: 2px solid ${theme`colors.sunset`};
              }
            `}
          >
            <Box tw="flex flex-row flex-nowrap items-center ">
              <Aside tw="flex flex-col w-2/3">
                <Box>
                  <H2 dangerouslySetInnerHTML={{ __html: cta.label }} />
                </Box>
                <Box tw="py-2">
                  <P dangerouslySetInnerHTML={{ __html: cta.description }} />
                </Box>
                <Box>
                  <Link to={cta.uri}>
                    <InnerText
                      tw="font-bold"
                      dangerouslySetInnerHTML={{
                        __html: ctaLabel,
                      }}
                    />
                  </Link>
                </Box>
              </Aside>
              <Figure tw="flex flex-col w-1/3">
                {ctaImage && <GatsbyImage image={ctaImage} alt="cta-image" />}
              </Figure>
            </Box>
          </Card>
        )
      })}
    </Section>
  )
}

export const Service = ({
  category = 'Services',
  title = 'Product design',
  description = 'Some descriptor text',
  anchor,
  image,
  contained = false,
  reverse = false,
}: {
  category?: string
  title?: string
  description?: string
  anchor?: string
  image?: unknown
  contained?: boolean
  reverse?: boolean
}) => {
  const Contained = contained ? Container : React.Fragment
  const sectionImage = image ? getImage(image as ImageDataLike) : null

  return (
    <GradientBackgroundBox
      tw="w-full"
      $gradient={reverse ? 'lightToGrey' : 'greyToLight'}
      id={anchor}
    >
      <Contained>
        <Box tw="py-16 px-4">
          <Box tw="flex xxs:flex-col sm:flex-row relative">
            <Box tw="xxs:w-full sm:w-1/2 xxs:px-1 md:px-4 flex-1 xxs:order-2 md:order-1 md:justify-center xxs:z-20">
              <Box>
                <BigH3 tw="py-1">{category}</BigH3>
                <BigH2 tw="py-3" style={{ textShadow: '0 0 5px white' }}>
                  {title}
                </BigH2>
                <Box tw="xxs:bg-white md:bg-transparent xxs:p-4 md:p-0 xxs:shadow md:shadow-none">
                  <BigP tw="py-2">{description}</BigP>
                </Box>
              </Box>
            </Box>
            <Box tw="xxs:w-full sm:w-1/2 flex-1 xxs:order-1 md:order-2 relative items-center xxs:z-10">
              <Box tw="xxs:w-50P md:w-4/5 xxs:absolute md:static xxs:left-50P xxs:-top-16">
                {sectionImage && (
                  <GatsbyImage image={sectionImage} alt="cta-image" />
                )}
              </Box>
            </Box>
          </Box>
          <Box></Box>
        </Box>
      </Contained>
    </GradientBackgroundBox>
  )
}

export const ContactUs = ({ reverse }: { reverse: boolean }) => {
  return (
    <GradientBackgroundBox
      tw="w-full"
      $gradient={reverse ? 'lightToGrey' : 'greyToLight'}
    >
      <Container>
        <Box tw="py-16 xxs:flex-col lg:flex-row xxs:items-center">
          <Box tw="xxs:w-full md:w-1/2 xxs:px-1 md:px-4 flex-1 md:items-start xxs:justify-center text-center">
            <BigH3
              dangerouslySetInnerHTML={{
                __html: 'Would you like to get in touch?',
              }}
            />
            <BigH3
              dangerouslySetInnerHTML={{
                __html: 'We&apos;ll be happy to talk!',
              }}
            />
          </Box>
          <Box tw="xxs:w-full md:w-1/2 xxs:flex-col md:flex-row flex-1 relative md:items-center md:justify-center xs:px-4 py-8">
            <Box tw="xxs:flex-col md:flex-row md:items-center">
              <Box tw="pr-1 xxs:w-full md:w-auto">
                <a href="mailto:hello@hazel.software?subject=quote for awesome software">
                  <ActionButton buttonText="Get a free estimate →" emoji="✉️" />
                </a>
              </Box>
              <Box tw="xs:px-4 py-8 self-center">
                <H3>or</H3>
              </Box>
              <Box tw="pl-1">
                <a href={process.env.GATSBY_CALENDARLY_URL} target="_blank">
                  <ActionButton buttonText="Schedule a meeting →" emoji="📅" />
                </a>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </GradientBackgroundBox>
  )
}
