import * as React from 'react'
import { Box, Container, Footer } from './Elements'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { H2, H3, P } from './Typography'
import { CTAButton } from './Buttons'

const GlobalFooter = () => {
  const data = useStaticQuery(graphql`
    query GlobalFooterData {
      allMessagesJson {
        nodes {
          global {
            en {
              company {
                name
                logo {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                offices {
                  address
                  name
                }
              }
              contact {
                contact_us
                contact_address
                contacts {
                  greeting
                  name
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
              services {
                keywords
              }
              technologies {
                keywords
              }
            }
          }
        }
      }
    }
  `)

  const footerData = data.allMessagesJson.nodes.find((n) => n.global !== null)
  const {
    global: {
      en: { company, contact, services, technologies },
    },
  } = footerData

  return (
    <Footer>
      <Container tw="flex xxs:flex-col md:flex-row flex-nowrap justify-between py-6 xxs:px-0 sm:px-6">
        <Box tw="xxs:w-full md:w-1/3 xxs:px-2 md:pr-2">
          {/* company info */}
          <Box>
            <Box>
              <H2 tw="text-snow">{company.name}</H2>
            </Box>

            <Box>
              <Box tw="flex flex-row py-4">
                <Box tw="w-1/5 flex flex-col justify-center">
                  <GatsbyImage
                    image={company.logo.childImageSharp.gatsbyImageData}
                    alt="logo"
                  />
                </Box>
                <Box tw="w-4/5 pl-8">
                  {company.offices.map((office) => {
                    return (
                      <Box key={office.name} tw="py-2">
                        <H3 tw="text-snow">{office.name}</H3>
                        <P tw="text-snow">{office.address}</P>
                      </Box>
                    )
                  })}
                </Box>
              </Box>
            </Box>
          </Box>
          {/* contact info */}
          <Box>
            <Box>
              <H2 tw="text-snow">{contact.contact_us}</H2>
            </Box>
            {contact.contacts.map((c, j) => {
              return (
                <Box key={j} tw="flex flex-row py-4">
                  <Box tw="w-1/5 flex flex-col justify-center">
                    <GatsbyImage
                      style={{ borderRadius: '50%' }}
                      image={c.image.childImageSharp.gatsbyImageData}
                      alt="logo"
                    />
                  </Box>
                  <Box tw="w-4/5 pl-8">
                    <H3 tw="text-snow">{c.name}</H3>
                    <P tw="text-snow">{c.greeting}</P>
                  </Box>
                </Box>
              )
            })}
          </Box>
          <Box tw="py-4 flex flex-row justify-center">
            <a href="mailto:hello@hazel.software">
              <CTAButton buttonText={contact.contact_address} />
            </a>
          </Box>
        </Box>
        <Box tw="xxs:w-full md:w-1/3  px-2">
          <H2 tw="text-snow">Services</H2>
          {services.keywords.map((keyword) => {
            return (
              <P key={keyword} tw="text-snow my-2">
                {keyword}
              </P>
            )
          })}
        </Box>
        <Box tw="xxs:w-full md:w-1/3  px-2">
          <H2 tw="text-snow">Technologies</H2>
          {technologies.keywords.map((keyword) => {
            return (
              <P key={keyword} tw="text-snow my-2">
                {keyword}
              </P>
            )
          })}
        </Box>
      </Container>
    </Footer>
  )
}

export default GlobalFooter
