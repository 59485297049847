import * as React from 'react'
import tw, { styled, css, theme } from 'twin.macro'
import { CTAButton } from './Buttons'
import HomeMessages from '../../../data/messages/home.json'

import { motion } from 'framer-motion'
import { Link } from 'gatsby'

// translations
const SELECTED_LANGUAGE = 'en'

// components
const Nav = styled(motion.nav)(() => [tw`relative z-50 flex items-center`])

const NavMenuContainer = styled(motion.ul)(() => [
  tw`flex items-center pr-4 xxs:hidden sm:flex`,
])

const NavMenuItem = styled(motion.li)(() => [
  tw`px-4 font-medium hover:text-sunset py-2 px-4`,
  css`
    &:hover {
      text-shadow: 0 1px 1px ${theme`colors.midnight-green`};
    }
  `,
])

const NavMenu = () => {
  return (
    <Nav>
      <NavMenuContainer>
        {HomeMessages.home[SELECTED_LANGUAGE].navItems.map((item, i) => {
          return (
            <Link key={i} to={item.uri}>
              <NavMenuItem>{item.label}</NavMenuItem>
            </Link>
          )
        })}
      </NavMenuContainer>
      <a href="mailto:hello@hazel.software?subject=quote for awesome software">
        <CTAButton
          buttonText={HomeMessages.home[SELECTED_LANGUAGE].buttons.navCTA}
        />
      </a>
    </Nav>
  )
}

export default NavMenu
