import tw, { styled, TwStyle } from 'twin.macro'
import { motion } from 'framer-motion'

export const Container = styled(motion.div)(() => [
  tw`flex flex-col mx-auto`,
  'max-width: 1024px;',
  'width: 100%;',
])

export const Header = styled(motion.header)(() => [tw`flex flex-col`])

export const Main = styled(motion.main)(() => [tw`relative flex flex-col`])

export const Section = styled(motion.section)(() => [
  tw`relative flex flex-col`,
])

export const Aside = styled(motion.aside)(() => [tw`relative flex flex-col`])

export const Figure = styled(motion.figure)(() => [tw`relative flex flex-col`])

export const Box = styled(motion.div)(() => [tw`relative flex flex-col`])

const gradientChoices = {
  goldToLight: tw``,
  greyToLight: tw`from-silverlight to-snow`,
  lightToGrey: tw`from-snow to-silverlight`,
} as Record<string, TwStyle>

export const GradientBackgroundBox = styled(Box)(
  ({ $gradient = 'greyToLight' }: { $gradient?: string }) => [
    tw`bg-gradient-to-b`,
    gradientChoices[$gradient],
  ]
)

export const Card = styled(motion.div)(() => [
  tw`relative flex flex-col bg-snow shadow-lg`,
])

export const Footer = styled(motion.footer)(() => [
  tw`flex flex-col bg-gunmetal`,
])
