import {
  useElementScroll,
  useTransform,
  useViewportScroll,
} from 'framer-motion'
import * as React from 'react'
import tw, { css, styled, GlobalStyles } from 'twin.macro'
import GlobalFooter from '../common/GlobalFooter'
import Nav from '../common/Nav'

const Page = styled.div(() => [
  tw`relative flex flex-col overflow-x-hidden overflow-y-auto`,
  css`
    flex: 1;
    & * {
      ${tw`select-none`}
    }
  `,
])

const Default: React.FC<{}> = ({ children }) => {
  const pageRef = React.useRef(null)
  const { scrollY } = useElementScroll(pageRef)
  const backgroundColor = useTransform(
    scrollY,
    [0, 192],
    ['rgba(255, 255, 255, 0)', 'rgba(255, 255, 255, 1)']
  )
  const boxShadow = useTransform(
    scrollY,
    [0, 192],
    ['0 0 0 0 rgba(255, 255, 255, 0)', '0px 20px 34px 0 rgb(0, 0, 0, 0.05)']
  )

  return (
    <>
      <GlobalStyles />
      <Page ref={pageRef}>
        <Nav backgroundColor={backgroundColor} boxShadow={boxShadow} />
        {children}
        <GlobalFooter />
      </Page>
    </>
  )
}

export default Default
